import * as React from 'react';

import { PageProps, graphql } from 'gatsby';
import { ImageDataLike, StaticImage, getImage } from 'gatsby-plugin-image';
import invariant from 'tiny-invariant';

import { team } from 'stile-shared/data/team';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { MediaBlock } from 'stile-shared/src/components/2020/blocks/MediaBlock';
import { TeamBlock } from 'stile-shared/src/components/2020/blocks/TeamBlock';
import { TeaserBlock } from 'stile-shared/src/components/2020/blocks/TeaserBlock';
import { TextBlock } from 'stile-shared/src/components/2020/blocks/TextBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { getPageData } from 'utils/getPageData';

import { PageLayout } from 'templates/2020/PageLayout';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export { Head } from 'templates/2020/Head';

type OurTeamPageProps = PageProps & {
  data: Queries.TeamImagesQuery;
};

function OurTeamPage({ data, ...props }: OurTeamPageProps) {
  const usTeam = getUsTeam(team, data);
  const leadershipTeam = getLeadershipTeam(team, data);
  const staffTeam = getStaffTeam(team, data);
  const { hero } = getPageData(props.location.pathname);

  return (
    <PageLayout {...props}>
      <HeroBlock
        {...hero}
        media={
          <MediaBlock as="div" layout="montage-five">
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/our-team/our_us_team_01.png"
              alt="Five Stile staff seated in a circle smiling at the camera"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/our-team/our_us_team_02.png"
              alt="Three Stile staff posing with our teacher materials, with plants in the background"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/our-team/our_us_team_03.png"
              alt="Three Stile staff doing a pottery workshop together"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/our-team/our_us_team_04.png"
              alt="The decoration in Stile's Portland office, including plants and our periodic table poster"
            />
            <StaticImage
              loading="eager"
              src="../../../../stile-shared/assets/images/our-team/our_us_team_05.png"
              alt="Six Stile staff members in front of their conference booth"
            />
          </MediaBlock>
        }
      />

      <TextBlock>
        <P>
          Stile was founded by Alan Finkel, Byron Scaf, and Danny Pikler, who together own and
          operate the company.
        </P>
        <P>
          Every aspect of Stile is crafted in-house by our passionate team of teachers, scientists,
          artists and engineers — a multidisciplinary group of the best and brightest. You’ll find
          Stilists scattered all around Australia, New Zealand and the United States with our global
          Headquarters based in Melbourne, Australia and US Headquarters in Portland, Oregon.
        </P>
      </TextBlock>

      <TeaserBlock
        variant="dark"
        title="Meet the Stile team"
        text="We asked the team how they would describe a Stile person. Here is what they had to say."
        video={{
          text: 'Watch (3 mins)',
          url: 'https://www.youtube.com/embed/tehcTUqr9t4',
          coverImage: (
            <StaticImage
              src="../../../../stile-shared/assets/images/our-team/culture_video_cover.jpg"
              alt="Several Stile staff members sitting in a relaxed meeting area. One is explaining something and the others are listening attentively"
            />
          ),
        }}
      />

      <TeamBlock variant="large" title="Team USA" items={usTeam} />

      <TeamBlock variant="large" title="Global Leadership" items={leadershipTeam} />

      <TeamBlock title="The dream team" items={staffTeam} />

      <NextPageBlock path="/who-we-are/join-the-team/" />

      <CtaBlock />
    </PageLayout>
  );
}

function getImageFromQueryData(image: string, imagesData: OurTeamPageProps['data']) {
  const imageData = getImage(
    imagesData.allFile.edges?.find(({ node }) => node?.base === image)
      ?.node as unknown as ImageDataLike
  );

  invariant(imageData, `Unable to find image for source ${image}`);

  return imageData;
}

function getUsTeam(teamData: typeof team, imagesData: OurTeamPageProps['data']) {
  return teamData
    .filter(({ categories }) => categories.includes('team-usa'))
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name, role, background, image }) => {
      return {
        name,
        role,
        background,
        image: getImageFromQueryData(image, imagesData),
      };
    });
}

function getLeadershipTeam(teamData: typeof team, imagesData: OurTeamPageProps['data']) {
  // `keyLeadership` should include the names of any staff to be 'hoisted' to the start of
  // the leadership section, in the order they should be displayed
  const keyLeadership = ['Alan Finkel', 'Daniel Pikler', 'Byron Scaf'];

  const filteredData = teamData
    .filter(({ name }) => keyLeadership.includes(name))
    .sort((a, b) => keyLeadership.indexOf(a.name) - keyLeadership.indexOf(b.name))
    .map(({ name, role, background, image }) => {
      return {
        name,
        role,
        background,
        image: getImageFromQueryData(image, imagesData),
      };
    });

  filteredData.push(
    ...teamData
      .filter(
        ({ categories, name }) =>
          categories.includes('leadership') &&
          !categories.includes('team-usa') &&
          !keyLeadership.includes(name)
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(({ name, role, background, image }) => {
        return {
          name,
          role,
          background,
          image: getImageFromQueryData(image, imagesData),
        };
      })
  );

  return filteredData;
}

function getStaffTeam(teamData: typeof team, imagesData: OurTeamPageProps['data']) {
  return teamData
    .filter(
      ({ categories }) => !categories.includes('leadership') && !categories.includes('team-usa')
    )
    .sort((a, b) => a.name.localeCompare(b.name))
    .map(({ name, role, image }) => ({
      name,
      role,
      image: getImageFromQueryData(image, imagesData),
    }));
}

export const query = graphql`
  query TeamImages {
    allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativePath: { glob: "*/portraits/*" } }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(width: 300)
          }
        }
      }
    }
  }
`;

export default OurTeamPage;
