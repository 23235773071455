import * as React from 'react';

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Card } from '../../common/Card';
import { H2, Section } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Frame } from '../../layout/Frame';

type TeamBlockProps = {
  title: string;
  variant?: 'large';
  items: {
    name: string;
    image: IGatsbyImageData;
    role: string;
    background?: string;
  }[];
};

export function TeamBlock({ title, items, variant }: TeamBlockProps) {
  return (
    <Section>
      <Column space={theme.space.l} align="center">
        <H2 size="xxl">{title}</H2>

        <TeamGrid variant={variant}>
          {items.map((member) => {
            return (
              <Card
                space={theme.space.xs}
                key={member.name}
                title={member.name}
                subtitle={member.role}
                text={member.background}
                image={
                  <Frame aspectRatio="5:6">
                    <GatsbyImage
                      image={member.image}
                      alt={`A black & white portrait of Stile team member ${member.name} smiling at the camera`}
                    />
                  </Frame>
                }
              />
            );
          })}
        </TeamGrid>
      </Column>
    </Section>
  );
}

const TeamGrid = styled.div<Pick<TeamBlockProps, 'variant'>>(
  ({ variant }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space.lToXl};
    justify-content: center;

    & > * {
      ${variant === 'large'
        ? css`
            flex: 1 1 200px;
            max-width: 300px;

            @media ${theme.media.min.s} {
              flex: 0 1 250px;
            }
          `
        : css`
            flex: 0 1 calc(50% - (${theme.space.gutters} * 2));
            max-width: 200px;

            @media ${theme.media.min.s} {
              flex: 0 1 calc(33% - (${theme.space.gutters} * 2));
            }

            @media ${theme.media.min.m} {
              flex-basis: 200px;
            }
          `};
    }
  `
);
